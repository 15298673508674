import CryptoJS from "crypto-js";
import SweetAlert from "../components/SweetAlert";

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_RIVETTE_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_IV);

export const timezones = [
  { id: 1, value: "America/New_York", label: "Eastern Time" },
  { id: 2, value: "America/Chicago", label: "Central Time" },
  { id: 3, value: "America/Denver", label: "Mountain Time" },
  { id: 4, value: "America/Phoenix", label: "Mountain Time (no DST)" },
  { id: 5, value: "America/Los_Angeles", label: "Pacific Time" },
  { id: 6, value: "America/Anchorage", label: "Alaska Time" },
  { id: 7, value: "America/Adak", label: "Hawaii-Aleutian" },
  { id: 8, value: "Pacific/Honolulu", label: "Hawaii-Aleutian Time (no DST)" },
];

export const timeH = [
  { id: 1, value: "01", label: "01" },
  { id: 2, value: "02", label: "02" },
  { id: 3, value: "03", label: "03" },
  { id: 4, value: "04", label: "04" },
  { id: 5, value: "05", label: "05" },
  { id: 6, value: "06", label: "06" },
  { id: 7, value: "07", label: "07" },
  { id: 8, value: "08", label: "08" },
  { id: 9, value: "09", label: "09" },
  { id: 10, value: "10", label: "10" },
  { id: 11, value: "11", label: "11" },
  { id: 12, value: "12", label: "12" },
];

export const timeS = [
  { id: 0, value: "00", label: "00" },
  { id: 5, value: "05", label: "05" },
  { id: 10, value: "10", label: "10" },
  { id: 15, value: "15", label: "15" },
  { id: 20, value: "20", label: "20" },
  { id: 25, value: "25", label: "25" },
  { id: 30, value: "30", label: "30" },
  { id: 35, value: "35", label: "35" },
  { id: 40, value: "40", label: "40" },
  { id: 45, value: "45", label: "45" },
  { id: 50, value: "50", label: "50" },
  { id: 55, value: "55", label: "55" },
  { id: 60, value: "60", label: "60" },
];

export const timeA = [
  { id: 1, value: "AM", label: "AM" },
  { id: 2, value: "PM", label: "PM" },
];

export const list_per_page_items = [
  { id: 5, label: "5", value: 5 },
  { id: 10, label: "10", value: 10 },
  { id: 15, label: "15", value: 15 },
  { id: 20, label: "20", value: 20 },
  { id: 25, label: "25", value: 25 },
  { id: 50, label: "50", value: 50 },
  { id: 100, label: "100 ", value: 100 },
];

export const items_per_page = 10;

export const campaign_types = [
  { id: 1, label: "Rewards program", value: 1 },
  { id: 2, label: "Discount offer", value: 2 },
  { id: 3, label: "Special products", value: 3 },
];

export const discount_type = [
  { id: 1, label: "Amount :", value: "0" },
  { id: 2, label: "Percentage :", value: "1" },
];

export const offer_type = [
  { id: 1, label: "Membership", value: "1" },
  { id: 2, label: "Ticket Purchase", value: "2" },
  { id: 3, label: "Birthday Packages", value: "3" },
];

export const display_offer_to = [
  { id: 1, label: "All Customers", value: "0" },
  { id: 2, label: "Members only", value: "1" },
];

export const status = [
  { id: 1, label: "Active", value: "1" },
  { id: 2, label: "Inactive", value: "0" },
];

// function to get the token from local storage
export const getToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user.token;
};

export const getRole = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user.role;
};

export const user_roles = [
  { id: 1, value: 1, label: "Admin" },
  { id: 2, value: 2, label: "User" },
];

// Decrypt function
export const decrypt = (encryptedMessage) => {
  // Decrypt the message
  const decrypted = CryptoJS.AES.decrypt(encryptedMessage, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  // Convert decrypted data to string
  return decrypted.toString(CryptoJS.enc.Utf8);
};

// Encrypt function
export const encrypt = (plaintext) => {
  // Encrypt the message
  const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  // Return the encrypted data as a base64 string
  return encrypted.toString();
};

export const messagePop = (response) => {
  if (response.status === "success") {
    SweetAlert.success("Success", response.message);
  } else if (response.status === "Info") {
    SweetAlert.info(response.status, response.message);
  } else if (response.status === "Warning") {
    SweetAlert.warning(response.status, response.message);
  } else if (response.status === "failed") {
    SweetAlert.warning(response.status, response.message);
  }
};
