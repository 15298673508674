import {React, useEffect }from 'react';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { OFFERSPRODUCTS } from "../../utils/Endpoints";
import { useRequest } from "../../utils/Requests";


const ChooseProducts = ({ open, setOpen, offerType, clientID, setSelectedProducts}) => {

    const apiRequest = useRequest();

    const [load, setLoad] = useState(false);
	const [selected, setSelected] = useState([]);
    const [data,setData] = useState([]);

	const handleClose = () => setOpen(false);

    useEffect(() => {
        
        const fetchProducts = async () => {
            setLoad(true);
            try {
                const response = await apiRequest({
                    url:OFFERSPRODUCTS, 
                    method:"get", 
                    params: {
                        offer_type: offerType,
                        location_id:clientID
                    }
                });

                if (response.data) 
                {
                    const products = response.data;
                    setData(products);
                    setLoad(false);
                } else {
                    console.error('Failed to fetch products:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        if (offerType && clientID) {
            fetchProducts();
        }
    }, [offerType, clientID]);

        
    const handleProductChange = (event) => {

        const productId = event.target.value;
        const isChecked = event.target.checked;

        setSelected(prevSelected => {
        if (isChecked) {
            return [
                ...prevSelected, 
                productId
            ];
        } else {
            return prevSelected.filter(id => id !== productId);
        }
        });
    };

    const addProducts = () => {
        // Filter the selected products based on their Pid
        const productsToAdd = data
            .flatMap(product => product.rest_products) // Flatten the nested Products array
            .filter(product => selected.includes(product.Pid)); // Filter by selected Pid
    
   
        // Set the selected products state
        setSelectedProducts(prevSelectedProducts => [
            // ...prevSelectedProducts,
            ...productsToAdd
        ]);
    
        // Close the modal
        setOpen(false);
    };


    return (
        <Modal show={open} onHide={handleClose} size="lg" aria-labelledby="modalLabel">
        <Modal.Header closeButton>
            <Modal.Title id="modalLabel">Add Products</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '250px', overflow: 'auto' }}>
            <div className="accordion mt-3" id="accordionWithIcon">
                {data.map((product, index) => (
                    <div className="accordion-item card show" key={product.parent_id}>
                    <h2 className="accordion-header d-flex align-items-center">
                        <button
                            type="button"
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target={`#accordionWithIcon-${product.parent_id}`}
                            aria-expanded="false"
                        >
                        <i className="bx bx-bar-chart-alt-2 me-2"></i>
                        <p className="mb-0 fs-13 fw-semibold">
                            {product.parent_name}
                        </p>
                        </button>
                    </h2>
                    <div id={`accordionWithIcon-${product.parent_id}`} className="accordion-collapse collapse">
                        <div className="accordion-body">
                        <div className="table-responsive text-nowrap">

                            {!load &&
                                <table className="table table-bordered RjTable">
                                    <thead>
                                        <tr>
                                        <th>#</th>
                                        <th>Image</th>
                                        <th>Item</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {product?.rest_products?.map((product, index) => (
                                        <tr key={index}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={product.name}
                                                    value={product.Pid}
                                                    onChange={handleProductChange}
                                                    data-image={product.imageUrl}
                                                />
                                            </td>
                                            <td>
                                                <img src={product.imageUrl} style={{width: '60px'}} alt={product.parent_name} />
                                            </td>
                                            <td className="fs-12">{product.name}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                        </div>
                        </div>
                    </div>
                    </div>
                ))}
                </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="refreshbtn" onClick={handleClose}>Cancel</button>
            <button type="button" className="ss_btn" onClick={addProducts}>Add Products</button>
        </Modal.Footer>
        </Modal>
    );
};

export default ChooseProducts;
