import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerComponent = (props) => {

  const {value, onChange, minDate} = props;

  return (
      <DatePicker
        selected={value}
        onChange={onChange}
        dateFormat="yyyy-MM-dd" // You can customize the date format
        className="fs-13 form-control"
        minDate={minDate && new Date()}
      />
  );
};

export default DatePickerComponent;
