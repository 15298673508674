import React from 'react'

export default function FormDropdown(props) 
{
  const {name, options, default_option, onChange, default_value, classnm} = props;

  return (
    <select className={classnm} defaultValue={default_value && default_value} name={name} onChange={onChange}>
      {(default_option) && <option value="">- Select -</option>}
      
      {options && options.map((option) => (
          <option key={option.id} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
}
