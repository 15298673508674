import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCEEditor = ({ value, onEditorChange }) => {
  return (
    <Editor
      value={value}
      init={{
        height: 500,
        menubar: false,
        license_key: "gpl",
        plugins:
          "advlist autolink lists link image charmap preview anchor emoticons searchreplace visualblocks code fullscreen insertdatetime media table  help wordcount",
        toolbar:
          "undo redo emoticons | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help ",
        content_style: `
          body {
            font-family: Poppins, sans-serif; /* Change to your desired font family */
            font-size: 13px; /* Change to your desired font size */
          }
        `,
        toolbar_sticky: true,
        skin: "oxide",
        // content_css: "/tinymce/skins/ui/oxide/skin.min.css",
        skin_url: "/tinymce/skins/ui/oxide",
        content_css: "/tinymce/skins/ui/oxide/content.min.css",
      }}
      onEditorChange={onEditorChange}
    />
  );
};

export default TinyMCEEditor;
